<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>耗能管理</el-breadcrumb-item>
            <el-breadcrumb-item>用电管理</el-breadcrumb-item>
            <el-breadcrumb-item>电量统计</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>

      <el-row class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>酒店：</label>
                  <el-select v-model="hotelId"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                           v-for="item in hotelList"
                           :key="item.id"
                           :label="item.hotelName"
                           :value="item.id"
                     ></el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>房间：</label>
                  <el-select v-model="room"  class="width-220" :placeholder="$t('msg.select')">
                     <el-option
                           v-for="item in roomList"
                           :key="item.index"
                           :label="item.name"
                           :value="item.index"
                     ></el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <span class="label">时间：</span>
                  <date-Packer :datePacker="dateValue"></date-Packer>
               </el-row>
               <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <div class="table-box">
            <el-table
                  ref="mainTable"
                  v-loading="loading"
                  :data="tableData"
                  style="width: 100%"
                  :stripe="true"
                  border fit
                  >
               <el-table-column prop="number" label="序号" width="90"></el-table-column>
               <el-table-column prop="room" label="房间" min-width="70"></el-table-column>
               <el-table-column prop="electricity" label="电量(度)"></el-table-column>
               <el-table-column prop="createTime" label="创建时间" min-width="70"></el-table-column>
               <el-table-column prop="hotel" label="所属酒店" min-width="70"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
         </div>
      </el-row>
   </div>
</template>

<script>
   import { urlObj } from "@/api/interface";
   export default {
      name: "goodsList",
      data() {
         return {
            tableData: [
               {'number':'1','room':'1001','electricity':'100','createTime':'2020-08-04 19:03:20','hotel':'维也纳清湖店'},
               {'number':'2','room':'1002','electricity':'110','createTime':'2020-08-04 19:03:23','hotel':'维也纳清湖店'},
               {'number':'3','room':'1003','electricity':'120','createTime':'2020-08-04 19:03:25','hotel':'维也纳清湖店'},
               {'number':'4','room':'1004','electricity':'130','createTime':'2020-08-04 19:03:26','hotel':'维也纳清湖店'}
            ], // 表格数据
            loading: false, // 加载中效果
            total: 10,
            limit: 1,
            dateValue: [], // 日期
            hotelId: '',
            hotelList: [
               { hotelName: "达易住大酒店", id: 1 },
            ],
            room: '',
            roomList: [
               { name: "大房", index: 1 },
               { name: "小房", index: 2 },
               { name: "上等房", index: 3 },
               { name: "总统套房", index: 4 },
            ],
         };
      },
      mounted() {},
      methods: {
         // 搜索列表
         handleQuery(){},
         // 改变每页数
         changePageNum(){},
         // 改变当前页
         changeCurrPage(){}
      },
   };
</script>

<style scoped></style>